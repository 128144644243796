(function($) {
  var alloyMainContent = $('.alloy-main-content');
  var alloyAllHeaders = $('h2, h3, h4, h5, h6', alloyMainContent);
  // console.warn(alloyAllHeaders);
  alloyAllHeaders.each(function(index, value){
    var alloyCurrentItem = $(this);
    alloyCurrentItem.attr('id', 'artikel-kop-id-' + index);
    alloyCurrentItem.append('<a class="alloy-article-headline-link" href="#artikel-kop-id-'+index+'">#</a>');
  });
})(jQuery); // Fully reference jQuery after this point.
